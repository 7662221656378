import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { bool } from 'prop-types';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import Button from '@/components/Button';
import { BUTTON_TYPE } from '@/types';

import { track } from '../../../modules/segment';

const FloatingCTA = ({ enableMobileFooterCtaOverlay, mobileFloatingCtaButton }) => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
  // Floating CTA has some special responsive design requirements that need special media queries between existing breakpoints
  const isSuperSmall = useMediaQuery('(max-width: 359px)');
  const mediumTablet = useMediaQuery('(min-width: 750px)');

  const [floatingCtaVisible, setFloatingCtaVisible] = useState(false);
  const [initialCtaViewLogged, setInitialCtaViewLogged] = useState(false);

  const floatingCtaEnabled = enableMobileFooterCtaOverlay && mobileFloatingCtaButton && isMobile;

  const handleScroll = () => {
    const currentScrollDepth = window.scrollY;
    const totalDocumentHeight = window.document?.body?.offsetHeight;
    const viewportHeight = window.innerHeight;
    const bottomOfViewport = currentScrollDepth + viewportHeight;

    if (currentScrollDepth > 900 && bottomOfViewport < totalDocumentHeight - 300) {
      setFloatingCtaVisible(true);
    } else {
      setFloatingCtaVisible(false);
    }
  };

  const throttledHandleScroll = _.throttle(handleScroll, 1000);

  useEffect(() => {
    if (typeof window !== 'undefined' && floatingCtaEnabled) {
      window.addEventListener('scroll', throttledHandleScroll);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', throttledHandleScroll);
      }
    };
  }, [floatingCtaEnabled]);

  useEffect(() => {
    if (floatingCtaVisible && !initialCtaViewLogged) {
      track('Floating CTA Viewed');
      setInitialCtaViewLogged(true);
    }
  }, [floatingCtaVisible, initialCtaViewLogged]);

  if (floatingCtaEnabled) {
    return (
      <Box
        zIndex={100}
        position="fixed"
        bottom={floatingCtaVisible ? 0 : -159}
        left={0}
        right={0}
        px={{ xs: 3, sm: 5 }}
        py={2}
        display="flex"
        justifyContent={isSuperSmall ? 'center' : 'space-between'}
        alignItems={mediumTablet ? 'center' : 'flex-end'}
        gap={2}
        sx={{ transition: 'bottom 250ms ease-in-out ' }}
      >
        <Box
          zIndex={100}
          bgcolor="secondary.darkest"
          position="absolute"
          mt={mediumTablet ? 4 : 3}
          top={0}
          bottom={0}
          left={0}
          right={0}
          sx={{ borderTopLeftRadius: 16, borderTopRightRadius: 16 }}
        />
        <Box
          flex={2}
          maxWidth={{ xs: 185, sm: mediumTablet ? 600 : 300 }}
          zIndex={101}
          display={{ xs: 'block', sm: mediumTablet ? 'flex' : 'block' }}
          alignItems="center"
          gap={4}
          pt={mediumTablet ? 3 : 0}
        >
          <Typography variant="h2" color="neutral.lightest" fontWeight={700} fontFamily="Unna" pt={2} pb={1}>
            <em>Moving</em> soon?
          </Typography>
          <Box maxWidth={210}>
            <Button
              color={mobileFloatingCtaButton.color}
              variant={mobileFloatingCtaButton.variant}
              href={mobileFloatingCtaButton.href}
              onClick={() => {
                track('Floating CTA Clicked');
              }}
              sx={{
                borderRadius: 1,
                px: 3,
                ...(mobileFloatingCtaButton.color === 'primary' ? { color: 'text.primary' } : {}),
              }}
            >
              <Typography variant="h3" component="span">
                {mobileFloatingCtaButton.label}
              </Typography>
            </Button>
          </Box>
        </Box>
        {isSuperSmall ? null : (
          <Box flex={1} minWidth={50} maxWidth={{ xs: 110, sm: mediumTablet ? 80 : 110 }} display="flex" zIndex={101}>
            <img src="/images/boxes.svg" alt="Stack of boxes" width="100%" />
          </Box>
        )}
      </Box>
    );
  }
  return null;
};

FloatingCTA.propTypes = {
  enableMobileFooterCtaOverlay: bool.isRequired,
  mobileFloatingCtaButton: BUTTON_TYPE.isRequired,
};

export default FloatingCTA;

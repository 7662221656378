import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { shape, string, arrayOf, bool } from 'prop-types';
import { AppBar, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@/components/Button';
import formatPhoneNumber from '@/utils/formatPhoneNumber';
import { BUTTON_TYPE, LINK_TYPE } from '@/types';
import Link from '../Link';
import FloatingCTA from './FloatingCTA';

const DynamicDrawer = dynamic(() => import('./Drawer'));
const DynamicNav = dynamic(() => import('./Nav'));

const Header = ({
  header: { logo, columns, phoneNumber, link, button, enableMobileFooterCtaOverlay, mobileFloatingCtaButton },
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={0} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Box
          height={88}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={1440}
          width="100%"
          margin="0 auto"
          px={{ xs: 2, md: 5 }}
        >
          {/* Logo */}
          <Box display="flex" sx={{ height: 35 }} flexGrow={1} justifyContent="flex-start">
            <Link href={logo.href}>
              <img width={150} src={logo.url} alt={logo.alt} />
            </Link>
          </Box>
          {/* General Navigation */}
          {columns?.length > 0 && <DynamicNav columns={columns} phoneNumber={phoneNumber} />}
          <Box display="flex" flexGrow={{ md: 2 }} justifyContent="flex-end" alignItems="center">
            {/* Phone Number (duplicated for smaller breakpoints) */}
            {phoneNumber && (
              <Box display={{ lg: 'none' }}>
                <Link
                  sx={{
                    padding: 1,
                    '&:hover': {
                      color: 'primary.main',
                      backgroundColor: 'neutral.lighter',
                      borderRadius: 50,
                    },
                  }}
                  href={`tel:${phoneNumber}`}
                  variant="body2"
                  color="text.primary"
                  display="flex"
                  alignItems="center"
                  fontFamily="h1.fontFamily"
                >
                  {formatPhoneNumber(phoneNumber)}
                </Link>
              </Box>
            )}
            {/* Secondary CTA } */}
            {link && (
              <Link
                display={{ xs: 'none', lg: 'flex' }}
                sx={{
                  padding: 1,
                  '&:hover': {
                    color: 'primary.main',
                    backgroundColor: 'neutral.lighter',
                    borderRadius: 50,
                  },
                }}
                href={link.href}
                variant="body2"
                color="text.primary"
                mr={2}
                fontFamily="h1.fontFamily"
              >
                {link.label}
              </Link>
            )}
            {/* Primary CTA } */}
            {button && (
              <Box display={{ xs: 'none', md: 'block' }} mx={{ md: 3, lg: 0 }}>
                <Button color={button.color} variant={button.variant} href={button.href}>
                  {button.label}
                </Button>
              </Box>
            )}
            {/* Hamburger Menu */}
            <Box display={{ lg: 'none' }}>
              <IconButton onClick={() => setMenuOpen(!menuOpen)} aria-label="hamburger menu">
                {menuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
        {menuOpen && (
          <DynamicDrawer drawerOpen={menuOpen} setDrawerOpen={setMenuOpen} link={link} button={button} columns={columns} />
        )}
      </AppBar>
      {/* Special floating CTA entry to the booking flow */}
      <FloatingCTA
        enableMobileFooterCtaOverlay={enableMobileFooterCtaOverlay}
        mobileFloatingCtaButton={mobileFloatingCtaButton}
      />
    </>
  );
};

Header.propTypes = {
  header: shape({
    logo: shape({
      alt: string,
      url: string,
    }),
    link: LINK_TYPE,
    phoneNumber: string,
    button: BUTTON_TYPE,
    columns: arrayOf(
      shape({
        label: string,
        href: string,
        title: string,
        links: arrayOf(LINK_TYPE),
      }),
    ),
    enableMobileFooterCtaOverlay: bool,
    mobileFloatingCtaButton: BUTTON_TYPE,
  }).isRequired,
};

export default Header;
